<template>
  <div id="month-card">
    <div class="card">
      <div class="header">
        <div class="c-header-text">
          {{ type === 'ENTRANCE' ? 'Entradas' : 'Saídas' }} (valor)
        </div>
        <div class="c-header-month flex-fill">
          <ChevronLeft class="chevron" @click="decrementMonth" />
          <date-picker
            type="month"
            placeholder=""
            v-model="date"
            format="MMMM"
            :clearable="false"
            :lang="langDatePicker"
          ></date-picker>
          <ChevronRight class="chevron" @click="incrementMonth" />
        </div>
      </div>
      <div class="container-value">
        <div class="value">
          <span class="value-prefix">R$</span
          >{{ parseNumberToMoney(total).trim().split('$')[1] }}
        </div>
        <div class="comp" :class="{ red: diff < 0, green: diff > 0 }">
          <span v-if="diff > 0" class="icon-box"
            ><ArrowUp class="icon stroke icon-arrow"
          /></span>
          <span v-if="diff < 0" class="icon-box"
            ><ArrowDown class="icon stroke icon-arrow"
          /></span>
          {{ parseInt(diff) }}%
        </div>
      </div>

      <div class="entry-tile" v-if="systemModule === 'Financial'">
        Movimentações
      </div>
      <div class="entry-tile" v-else>
        {{ type === 'ENTRANCE' ? 'Entradas' : 'Saídas' }} (produtos)
      </div>
      <div class="entry-value-qnt">{{ count }}</div>
      <div class="description">Porcentagem referente ao mês anterior.</div>
    </div>
  </div>
</template>

<script>
import { parseNumberToMoney } from '@/utils/moneyHelper'
import { getCurrentClinic } from '../../utils/localStorageManager'

export default {
  components: {
    ArrowDown: () => import('@/assets/icons/arrow-down.svg'),
    ArrowUp: () => import('@/assets/icons/arrow-up.svg'),
    ChevronLeft: () => import('@/assets/icons/chevron-left.svg'),
    ChevronRight: () => import('@/assets/icons/chevron-right.svg'),
  },
  async mounted() {
    await this.getMonthlyResults()
  },
  props: {
    type: { type: String, required: true },
    api: { type: Function, required: true },
    systemModule: String,
    bankAccountId: String
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      total: '-',
      count: '-',
      diff: 0,
      date: new Date(),
      langDatePicker: {
        formatLocale: {
          weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
        }
      }
    }
  },
  methods: {
    parseNumberToMoney,
    incrementMonth() {
      const date = new Date(this.date.getTime())
      date.setMonth(this.date.getMonth() + 1)
      this.date = date
    },
    decrementMonth() {
      const date = new Date(this.date.getTime())
      date.setMonth(this.date.getMonth() - 1)
      this.date = date
    },
    async getMonthlyResults() {
      const isLoading = this.$loading.show()
      try {
        let response = null
        if(this.bankAccountId){
          response = await this.api(
            this.clinic.id,
            this.type,
            this.date.getMonth(),
            this.date.getFullYear(),
            this.bankAccountId
          )
        }
        else{
          response = await this.api(
            this.clinic.id,
            this.type,
            this.date.getMonth(),
            this.date.getFullYear(),
          )
        }

        this.total = response?.data?.total
        this.count = response?.data?.count
        this.diff = response?.data?.diff
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    }
  },
  watch: {
    async date() {
      await this.getMonthlyResults()
    },
    async bankAccountId() {
      await this.getMonthlyResults()
    }
  }
}
</script>
<style lang="scss">
#month-card {
  height: 100%;
  .card {
    display: flex;
    width: 100%;
    height: 100%;
    min-width: 335px;
    padding: 0.8rem 1.5rem;
    border: 1px solid var(--neutral-200);
    border-radius: 8px;
    text-align: left;
  }
  .header {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
  }
  .c-header-text {
    font-size: 16px;
    font-weight: 700;
    color: var(--dark-blue);
    width: 50%;
  }
  .c-header-month {
    display: inline-flex;
    align-items: center;
    justify-content: end;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
    text-align: center !important;
    width: 50%;
  }
  .chevron {
    width: 24px;
    height: 24px;
    stroke: var(--blue-500);
    cursor: pointer;
  }
  .mx-datepicker {
    width: 80%;
    .mx-input-wrapper {
      .mx-input {
        padding: 0 1rem 0 0;
        border: 0;
        box-shadow: none;
        color: var(--blue-500);
        font-weight: 600;
        font-size: 14px;
        text-align: center;
        width: 90%;
        cursor: pointer;
        
        &:focus {
          border: 1px solid var(--blue-500);
        }
      }
      .mx-icon-calendar {
        cursor: pointer;
      }
    }
  }
  .icon-chevron {
    width: 20px;
    height: 20px;
    cursor: pointer;
    stroke: var(--blue-500);
  }
  .container-value {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    margin: .5rem 0 1rem;
  }
  .value-prefix {
    font-size: 18px;
    font-weight: 700;
    color: var(--type-active);
  }
  .value {
    font-size: 1.5rem;
    color: var(--type-active);
    font-weight: 700;
  }
  .comp {
    display: inline-flex;
    align-items: center;
    border-radius: 30px;
    margin-left: 20px;
    padding: 4px 10px;
    color: white;
    font-weight: 600;
    font-size: 12px;
  }
  .icon-arrow {
    width: 10px;
    height: 10px;
    margin-right: 5px;
  }
  .red {
    background-color: var(--states-error);
  }
  .green {
    background-color: var(--states-success);
  }
  .entry-title {
    font-size: 16px;
    font-weight: 500;
    color: var(--type-active);
  }
  .entry-value-qnt {
    font-size: 2rem;
    font-weight: 700;
    color: var(--type-placeholder);
  }
  .description {
    font-size: 14px;
    font-weight: 600;
    color: var(--type-placeholder);
  }
}
</style>
